<template>
  <div class="wrap">
    <div class="top-banner">
      <div class="w-1200">
        <div class="title">数邦大数据平台</div>
        <div class="en">DU-UDP</div>
        <p>通过大数据产品灵活组合，可以支撑湖仓一体化、</p>
        <p>实时数据入湖、存算分离等应用场景。</p>
      </div>
    </div>
    <div class="main-box w-1200">
      <div class="main-item">
        <div class="title">功能介绍</div>
        <div class="content">
          <div class="function-box">
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-1.png"/>
              <label>主机管理</label>
              <p>管理加入集群的主机信息，包含CPU、内存、磁盘使用情况以及实时与历史的监控；主机的新增与删除等操作。</p>
            </div>
            <div class="function-item">
              <img src="~@/assets/images/product/function-icon-2.png"/>
              <label>组件管理</label>
              <p>
								组件安装、扩容、缩容相关部署操作，
								<br>
								组件的配置管理:通过该功能实现对组件的配置进行修改调整，分发到各个实例，根据配置情况确定是否需要重启生效，以及版本管理与回退功能。
								<br>
								组件状态监控与管理：启动、停止、重启、滚动重启，重要性能指标可视化监控与预警，组件原生管理页面链接等。
							</p>
            </div>
          </div>
        </div>
      </div>
      <div class="main-item">
        <div class="title">产品架构</div>
        <div class="content">
          <img src="~@/assets/images/udp-architecture.png"/>
        </div>
      </div>
      <div class="main-item">
        <div class="title">核心优势</div>
        <div class="content">
          <div class="core-box">
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-1.png"/>
              <div class="text-box">
                <label>高度化配置</label>
                <p>集群所有组件运行参数均支持在线调整配置，配置后自动同步到组件相关节点，并根据实际需要重启，所有变更均有版本管理操作，可根据需要回退到指定版本，零后端管理操作</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-2.png"/>
              <div class="text-box">
                <label>湖仓一体化</label>
                <p>通过大数据产品灵活组合，可以支撑湖仓一体化、实时数据入湖、存算分离等应用场景</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-3.png"/>
              <div class="text-box">
                <label>实时数据服务</label>
                <p>可使用基于es、hbase，Clickhouse等产品的组合，提供毫秒级实时数据服务</p>
              </div>
            </div>
            <div class="core-item">
              <img src="~@/assets/images/product/core-icon-4.png"/>
              <div class="text-box">
                <label>统一控制</label>
                <p>统一数据访问控制、统一数据授权、数据脱敏策略管理、统一审计</p>
              </div>
            </div>
          </div>
        </div>
      </div>
			<div class="main-item">
				<div class="title">组件介绍</div>
				<ul class="table-header">
					<li>组件名称</li>
					<li>版本号</li>
					<li>组件简介</li>
				</ul>
				<div class="table-content">
					<table >
						<tbody>
							<tr>
								<td>Kerberos</td>
								<td>1.20</td>
								<td>基础组件，提供认证服务</td>
							</tr>
							<tr>
								<td>Zookeeper</td>
								<td>3.8.0</td>
								<td>基础组件，提供高可用的协调服务</td>
							</tr>
							<tr>
								<td>Hdfs</td>
								<td>3.3.4</td>
								<td>分布式文件系统，实现大规模数据可靠的分布式读写</td>
							</tr>
							<tr>
								<td>Yarm</td>
								<td>3.3.4</td>
								<td>统一的资源管理框架</td>
							</tr>
							<tr>
								<td>Flink</td>
								<td>1.16.0</td>
								<td>批处理和流处理结合的统一计算框架</td>
							</tr>
							<tr>
								<td>Kafka</td>
								<td>2.13-3.31</td>
								<td>分布式、分区、多副本的消息发布-订阅系统</td>
							</tr>
							<tr>
								<td>Hive</td>
								<td>3.1.2</td>
								<td>建立在Hadoop上的数据仓库基础构架</td>
							</tr>
							<tr>
								<td>Spark</td>
								<td>3.3.1</td>
								<td>基于内存的分布式计算框架</td>
							</tr>
							<tr>
								<td>lceberg</td>
								<td>1.1.0</td>
								<td>数据湖组件，支撑实时数据入湖，支撑通过Trino、Spark、Flink等计算引擎进行计算</td>
							</tr>
							<tr>
								<td>Hbase</td>
								<td>2.4.15</td>
								<td>合存储海量非结构化数据或半结构化数据、具备高可靠性、高性能、可灵活扩展伸缩的、支持实时数据读写的分布式存储系统</td>
							</tr>
							<tr>
								<td>Hbase</td>
								<td>2.4.15</td>
								<td>合存储海量非结构化数据或半结构化数据、具备高可靠性、高性能、可灵活扩展伸缩的、支持实时数据读写的分布式存储系统</td>
							</tr>
							<tr>
								<td>Phoenix</td>
								<td>2.4-5.1.2</td>
								<td>提供了一种全新（SQL）的方式来操作HBase中数据，从而降低了使用Hbase的门槛</td>
							</tr>
							<tr>
								<td>Trino</td>
								<td>410</td>
								<td>主要应用于海量结构化数据/半结构化数据分析、海量多维数据聚合/报表、ETL、Ad-Hoc查询等场景</td>
							</tr>
							<tr>
								<td>ClickHouse</td>
								<td>21.8.6.15</td>
								<td>ClickHouse是一款开源的面向联机分析处理的列式数据库，其独立于Hadoop大数据体系，最核心的特点是极致压缩率和极速查询性能。同时，ClickHouse支持SQL查询，且查询性能好，特别是基于大宽表的聚合分析查询性能非常优异，比其它分析型数据库速度快一个数量级</td>
							</tr>
							<tr>
								<td>Redis</td>
								<td>6.2.6</td>
								<td>包含多种数据结构、支持网络、基于内存、可选持久性的键值对存储数据库</td>
							</tr>
							<tr>
								<td>Elasticsearch</td>
								<td>8.0.0</td>
								<td>基于Lucene的实时分布式的搜索与分析引擎</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
      <div class="main-item">
        <div class="title">应用场景</div>
        <div class="content">
          <div class="scenario-box">
            <div class="scenario-item">
              <label>金融行业</label>
              <p>金融科技对数据处理实时性、稳定性、多样性、高效性等要求较高。本公司的大数据产品集成各类组件，能够较好的满足金融行业各种使用场景</p>
              <img src="~@/assets/images/product/scenario-1.png"/>
            </div>
            <div class="scenario-item">
              <label>政务行业</label>
              <p>政务行业，依托数据湖组件，提供实时数据入湖；依托大数据的运算能力，从15分钟+的异步服务，提升到秒级同步数据处理，并对外提供服务；依托大数据组件提供毫秒级实时数据服务</p>
              <img src="~@/assets/images/product/scenario-2.png"/>
            </div>
            <div class="scenario-item">
              <label>数据应用，助力企业数字化转型</label>
              <p>分析、管理数据，基于硬件和业务数据，完成企业的事前风险预测、事中监控调度和事后问题排查工作，保障生产和供应的可靠性</p>
              <img src="~@/assets/images/product/scenario-1.png"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.wrap {
  background-color: #F7F8FA;
}
.table-header {
	display: flex;
	background-color: #C9CEE2;
	margin-bottom: 20px;
	width: 100%;
	border-radius: 3px;
	padding: 20px 0;
	font-size: 16px;
	font-weight: bold;
	:not(:last-child) {
		width: 200px;
		text-align: center;
	}
	:last-child {
		padding-left: 30px;
	}
}
.table-content {
	width: 100%;
  background-color: #fff;
	border-radius: 3px;
	overflow: hidden;
	border: 1px solid #e8e8e8;
	table {
		width: 100%;
		tbody {
			tr {
				&:first-child {
					td {
						border-top: none;
					}
				}
				&:last-child {
					td {
						border-bottom: none;
					}
				}
				td {
					padding: 15px 0;
					border: 1px solid #e8e8e8;
				}
				:not(:last-child) {
					width: 200px;
					text-align: center;	
				}
				:last-child {
					border-right: none;
					padding-left: 30px;
					padding-right: 30px;
				}
				:first-child {
					border-left: none;
				}
			}
		}
	}
}
.top-banner {
  height: 320px;
  background-image: url('~@/assets/images/product/banner.png');
  background-repeat: no-repeat;
  background-position: center;
  .title {
    font-size: 40px;
    font-weight: 100;
    color: #FFFFFF;
    margin-bottom: 20px;
    padding-top: 82px;
  }
  .en {
    font-size: 40px;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 20px;
  }
  p {
    font-size: 18px;
    font-weight: 100;
    color: #FFFFFF;
    opacity: 0.8;
    margin-bottom: 14px;
  }
}
.main-box {
  .title {
    font-size: 20px;
    font-weight: 500;
    color: #4F5969;
    padding: 40px 0;
  }
  .content {
    img {
      width: 1199px;
    }
  }
  .function-box {
    overflow: hidden;
    .function-item {
      box-sizing: border-box;
      float: left;
      width: 589px;
      height: 244px;
      background: #FFFFFF;
      border-radius: 5px;
      padding: 30px;
      margin-right: 19px;
      >img {
        width: 26px;
      }
      &:nth-child(2n){
        margin-right: 0;
      }
      label {
        display: block;
        margin-top: 20px;
        font-size: 24px;
        font-weight: 400;
        color: #1D2129;
      }
      p {
        margin-top: 22px;
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        line-height: 1.2;
      }
    }
  }
  .core-box {
    overflow: hidden;
    .core-item {
      padding: 40px 28px 40px 30px;
      float: left;
      box-sizing: border-box;
      width: 590px;
      height: 159px;
      background: #FFFFFF;
      border-radius: 5px;
			margin-bottom: 20px;
      >img {
        width: 72px;
      }
      &:nth-child(2n-1) {
        margin-right: 20px;
      }
      img {
        float: left;
      }
      .text-box {
        margin-left: 102px;
        label {
          font-size: 24px;
          font-weight: 400;
          color: #1D2129;
        }
        p {
          margin-top: 22px;
          font-size: 16px;
          font-weight: 400;
          color: #87909C;
          line-height: 1.2;
        }
      }
    }
  }
  .scenario-box {
    overflow: hidden;
    margin-bottom: 60px;
    .scenario-item {
      padding-top: 30px;
      float: left;
      width: 386px;
      background: #FFFFFF;
      border-radius: 5px;
      >img {
        width: 386px;
      }
      &:not(:last-child){
        margin-right: 19px;
      }
      label {
        font-size: 20px;
        font-weight: 400;
        color: #1D2129;
        padding: 30px 30px 0;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #87909C;
        margin-top: 22px;
        margin-bottom: 30px;
        padding: 0 30px;
        height: 77px;
        line-height: 1.2;
      }
    }
  }
}
</style>
